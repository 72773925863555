
.slider {
    background: #EAEAEB;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 80vh;
    padding: 16px 0px;
    margin: 0 0 30px 0;
}


.slider .nav-link-meble{
    font-size: 4rem;
    font-weight: 250;
    color: #141314;
    text-decoration: none;
    margin-bottom: 30px;
    display: block;
    transition: all 0.3s ease;
}

.slider .nav-link-meble:hover {
    font-weight: 400;
}

.slider::before, .slider::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    animation: scroll 80s linear infinite;
    display: flex;
    width: calc(510px * 14);
    margin-top: 0px;
}


/* .slider .slide-track:hover {
    animation-play-state: paused;
} */

.slider .slide {
    height: 500px;
    width: 500px;
    margin: 0 5px;
    border-radius: 7px;
}

.slider .slide img {
    width: 500px;
    height: 500px;
    border-radius: 7px;
    object-fit: cover;
}



@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-510px * 7));
    }
}


@media only screen and (max-width: 700px) {
    .slider .nav-link-meble{
        display: block;
        padding-left: 0;
    }
}

