@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Zen+Kaku+Gothic+New:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
html {
    overflow-y: scroll;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.1rem;
    color: #141314;
    min-height: 100vh;
}

body {
    min-width: 340px;
    margin: auto;    
    background-color: #EAEAEB;
}

.cursive-font {
    font-family: "Nothing You Could Do", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 4.5rem;
}
  
.zen-kaku-gothic-new-regular {
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;

  }

  .zen-kaku-gothic-new-very-big {
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 5rem;

  }

.monsterrat-font {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: none; /* Initially hide it */
  z-index: 1000; /* Ensure it's on top of everything */
  align-items: center;
  justify-content: center;
}

#overlay-photo {
    max-width: 900px;
    display: block;
    margin-top: 20px;
    width: 80%; /* Adjust the margin-top as needed */
}  

main {
    padding-top: 0px;
    margin: 8px;
    margin-top: 50px;
} 


h1 {
    cursor: default;
}

footer {
    max-width: 1920px;
}

textarea {
    font-family: 'Unbounded', sans-serif;
}


@media only screen and (max-width: 950px) {
  
    
}


@media only screen and (max-width: 790px) {
    #gallery {
        padding: 0;
        padding-top: 60px;
    }

    
}


@media only screen and (max-width: 580px) {

   
   
    
    
    .homemade-apple-regular {
        font-size: 3rem;
    }
    
    .cursive-font {
        font-size: 4rem;
    }
    
}
