
.gallery {
  margin-top: 8px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: calc(6vw); /* Divide the container height into 20 rows */

}

/* Adjusted CSS selectors using .gallery-item class */
.gallery-item {
  position: relative; /* Ensure positioning for absolute placement */
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
}

.rectangle-x {
  width: 100%;
  height: 100%;
}

.gallery-img-container {
  flex-grow: 1;
  overflow: hidden;
}

.gallery-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
  border-radius: 7px;
}

.gallery-item-desc {
  border-radius: 7px;
  width: 100%; /* Set width to match parent width */
  background-color:rgba(234, 234, 235, 0.5);/* Example background color for description */
  padding: 8px; /* Adjust padding as needed */
  box-sizing: border-box; /* Include padding and border in width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-size: 0.8rem;
}

.gallery-item-price {
  font-weight: 450;
}




.gallery > .gallery-item:nth-child(14n+1),
.gallery > .gallery-item:nth-child(14n+6),
.gallery > .gallery-item:nth-child(14n+9),
.gallery > .gallery-item:nth-child(14n+10) {
  grid-area: span 4/span 3;
}

.gallery > .gallery-item:nth-child(14n+2) {
  grid-area: span 4/span 7;
}

.gallery > .gallery-item:nth-child(14n+3) {
  grid-area: span 4/span 2;
}

.gallery > .gallery-item:nth-child(14n+4),
.gallery > .gallery-item:nth-child(14n+8),
.gallery > .gallery-item:nth-child(14n+11) {
  grid-area: span 4/span 5;
}

.gallery > .gallery-item:nth-child(14n+5),
.gallery > .gallery-item:nth-child(14n+13),
.gallery > .gallery-item:nth-child(14n+14) {
  grid-area: span 4/span 4;
}

.gallery > .gallery-item:nth-child(14n+7) {
  grid-area: span 7/span 4;
}

.gallery > .gallery-item:nth-child(14n+12) {
  grid-area: span 5/span 4;
}


@media only screen and (max-width: 790px) {
  .gallery {
    display: block;
  }
  .gallery-item .rectangle-x {
    display: none;
  }

  .gallery-item {
    margin-bottom: 24px;
  }

  .gallery-item-desc {
    margin-top: 0;
  }


}