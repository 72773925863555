#contact-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EAEAEB;
    z-index: 999; /* Ensure it's above other content */
    box-sizing: border-box;
    padding: 16px;
    opacity: 0;
    transition: all 0.5s ease;
    overflow-y: auto;
}

/*for modal scrolling*/
body.modal-open {
    overflow: hidden;
}

.visible {
    opacity: 1 !important;
    transition: all 0.5s ease;
}

#closeBtnContactModal svg {
    margin: 0;
    transition: all 0.5s ease;
}

#closeButtonContactSVG line, #closeButtonContactSVG rect {
    transition: all 0.3s ease;
}

#closeButtonContactSVG:hover line{
    stroke: #a9b1aa;
}

#closeButtonContactSVG:hover rect {
    stroke: #a9b1aa;
    fill: #eaeaeb;
}
#contact-modal h1 {
    font-size: 4rem;
    font-weight: 250;
    margin-top: 100px;
}

#contact-modal p {
    font-size: 1rem;
    margin-bottom: 50px;
}

.contact-info-email-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;  
}


.contact-info-tel-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
     
}

.contact-info-email-modal span {
    font-size: 0.8rem;
    margin-left: 21px;
}

.contact-info-tel-modal span {
    font-size: 0.8rem;
    margin-left: 8px;
}

#contact-modal #zapytanie {
   margin-top: 50px;
   margin-bottom: 20px;
}

#contact-modal #contact-form {
    display: flex;
    flex-direction: column;
}

#contact-modal #contact-form #form-row {
    display: flex;
    
}

#contact-modal #form-name {
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-radius: 25px;
    border-style: dashed;
    flex: 1;
    margin-right: 8px;
    
    padding: 16px;
}

#contact-modal #form-email {
    
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-radius: 0;
    border-style: dashed;
    border-radius: 25px;
    flex: 1;
    padding: 16px;
    box-sizing: border-box;
}

#contact-modal textarea {
    
    font-weight: 300;
    width: 100%;
    height: 140px;
    margin-top: 8px;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-radius: 25px;   
    border-style: dashed; 
    box-sizing: border-box; 
    padding: 16px;
    resize: none;
}

#contact-modal #contact-form button {
    height: 45px;
    width: 200px;
    margin-top: 8px;
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    border: 1px solid #141314;
    border-radius: 25px;
    background-color: #EAEAEB;
    align-self: flex-end;
    color: #141314;
    transition: all 0.3s ease;
}

#contact-modal #contact-form button:hover {
    background-color: #141314;
    color: #A9B1AA; 
}

#contact-modal #form-name:focus,
#contact-modal #form-email:focus,
#contact-modal textarea:focus {
    outline: none; /* Remove default outline on focus */
    border-color: black; /* Set the border color when input is focused */
}
