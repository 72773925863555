#quote2 {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

#quote2-content {
    width: 100%;
    max-width: 1250px;
    padding: 100px 8px;
}

#quote2 h1{
    font-weight: 200;
    font-size: 4rem;
    margin: 0;
    transition: font-weight 0.3s ease;
}


#quote2 #first-line {
    text-align: end;
}

#quote2:hover h1 {
    font-weight: 300;
}

#quote2 img {
    align-self: flex-start;
    padding-left: 8px;
    position: relative;
    bottom: 8px;
    height: 15px;

}