/* PRODUCT PAGE */

.flex-container-product {
    display: flex;
    justify-content: center;
    height: 93vh;
}

#product-info {
    width: 30%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#info-row-1 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    height: 100%;
}

#info-row-2 {
    display: flex;
    justify-content: space-between;
}

#info-col-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 155px;
}

#info-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 8px;
    padding-bottom: 50px;
}

#info-col-2 span {
    font-size: 13px;
    margin-top: 8px;
}

#materialy-container {
    display: flex;
    justify-content: space-between;
    display: none;
}

#materialy-flex {
    display: flex;
}

#materialy-flex-metal {
    margin-left: 8px;
}
.arrows-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

#prev-img-btn {
    position: absolute;
    left: 0;
    flex-shrink: 0;
    width: 40%;
    height:100%;
}

#next-img-btn {
    position: absolute;
    right: 0;
    flex-shrink: 0;
    width: 40%;
    height: 100%;
}

.arrow-left {
    flex-shrink: 0;
    position: absolute;
    left: 8px;
    height: 100%;
    opacity: 1;
}

.arrow-right {
    flex-shrink: 0;
    position: absolute;
    height: 100%;
    right: 8px;
    opacity: 1;
}

.transparent {
    opacity: 0;
}


.arrow-left, .arrow-right, #arrow-left-thin, #arrow-right-thin {
    transition: all 1s ease;
}

.arrow-left-thin:hover line, .arrow-right-thin:hover line, .arrow-left-thin:hover rect, .arrow-right-thin:hover rect{
    stroke:#141314;
}

#arrow-left-thin:hover path, #arrow-right-thin:hover path {
    fill: #141314;
}

#arrow-left-thin path, #arrow-right-thin path {
    transition: all 0.4s ease;
}

img {
    user-select: none;
}

#drewno-kolor {
    height: 40px;
    width: 50px;
    margin: 0 3px;
    border-radius: 25px;
    overflow: hidden;
}


#metal-kolor {
    height: 40px;
    width: 50px;
    margin: 0 3px;
    border-radius: 25px;
}

#product-photo-container {
    display: flex; /* Ensures flex properties apply to children */
    width: 70%;
    position: relative;
}

#product-photo{
    width: 100%;
    object-fit: cover;
}

#product-name {
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 0px;
}


#price {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 20px;
}

#desc {
    font-weight: 300;
    font-size: 15px;
}

#dimensions {
    margin-top: 0;
    margin-bottom: 0;
}

#dimensions p {
    font-size: 13px;
    font-weight: 300;
    margin: 0 0 0 0;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}

#dimensions span {
    font-size: 13px;
    font-weight: bold;
}
/* 
#dimensions p:not(:last-child) {
    border-bottom: 1px solid #A9B1AA;
} */


#product-info button {
    border: 1px solid #141314;
    border-radius: 25px;
    width: 100%;
    height: 35px;
    margin-top: 30px;
    font-size: 14px;
    color: #141314;
    background-color: #EAEAEB;
    font-family: "Unbounded", sans-serif;
    transition: all 0.4s ease;
}

#product-info button:hover {
    background-color: #141314;
    color: #A9B1AA; 
}

#product-info #button-container-2 {
    display: none;
}

#materialy-span {
    margin-bottom: auto;
    font-size: 14px;
    display:none;
}


#stal-container span, #drewno-container span {
    font-size: 14px;
}



#closeButton {
    text-align: right;
    z-index: 400;
}

#closeButtonProductSVG line, #closeButtonProductSVG rect {
    transition: all 1s ease;
}

#closeButtonProductSVG:hover line{
    stroke: #a9b1aa;
}

#closeButtonProductSVG:hover rect {
    stroke: #141314;
    fill: #141314;
}





@media screen and (max-width: 1230px) {
    #materialy-container {
        display: flex;
        margin-bottom: 22px;
    }

    #materialy-span {
        display: block;
    }

    #info-col-2 {
        display: none;
    }

    
}



@media screen and (max-width: 1100px) {

    .flex-container-product {
        height: auto;
        padding: 0;
        flex-direction: column-reverse;
    }

    #product-photo-container {
        padding-right: 0;
        text-align: center;
        width: 100%;
    }

    .arrow-left {
        height: 100%;
    }
    
    .arrow-right {
        height: 100%;
    }

    #product-name {
        margin-bottom: 0px;
        margin-top: 0;

    }

    #desc {
        
        font-size: 15px;
        margin-right: 120px;
    }


    #product-info {
        width: 100%;
        margin-top: 0;
        box-sizing: border-box;
    }

    #info-row-1 {
        width: 100%;
    }

    #info-row-2 {
        flex-direction: row;
    }


    #product-info #closeButton {
        position: absolute;
        top: 60px;
        left: 16px;
    }




   


    #materialy-container {
        display: none;
    }

    #materialy-span {
        display: none;
    }

    #info-col-2 {
        display: flex;
    }
    
}

@media screen and (max-width: 790px) {   

    #product-photo{
        width:100%;
    }

    
}

@media screen and (max-width: 500px) {
    
    #info-col-1 {
        max-width: none;
    }

    #materialy-container {
        display: flex;
        flex-direction: row;
    }

    #materialy-span {
        display: block;
    }

    #info-col-2 {
        display: none;
    }

    #drewno-container {
        width:48%;
    }
    #stal-container {
        width:48%;
    }

    #drewno-kolor {
        width: 100%;
        height: 50px;
    }

    

    #metal-kolor {
        width: 100%;
        height: 50px;
    }

    .arrow-left-thin, .arrow-right-thin {
        width: 27%;
        height: 40px;
    }

    #desc {
        margin-right: 0;
    }
    
}
