#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#about h1 {
    font-weight: 250;
    font-size: 4rem;
    margin:70px 0px 30px 0px;
}

#about p {
    margin-bottom: 100px;
}

@media screen and (max-width<500px) {
    #about {
        height: auto;
    }
    
}