#contact {
    margin-top: 8px;
    padding-bottom: 100px;
    padding-top: 80px;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

#contact h1 {
    font-size: 4rem;
    font-weight: 250;
    margin: 0;
}

#contact p {
    font-size: 1.1rem;
    margin-top: 30px;
    margin-bottom: 30px;
}

#contact-bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

#contact-info {
    display: flex;
    align-items: center;
}

#contact-info-email {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

#contact-info-tel {
    display: flex;
    align-items: center;
}

#contact-info span {
    font-size: 0.9rem;
    font-weight: 800;
    margin-left: 8px;
    margin-right: 8px;
}

#contact #zapytanie {
   margin-top: 0;
   margin-bottom: 16px;
}

#contact #contact-form {
    display: flex;
    flex-direction: column;
}

#contact #contact-form #form-row {
    display: flex;
    margin: 0;
    
}

#contact #form-name {
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-style:dashed;
    border-radius: 25px;
    flex: 1;
    margin-right: 8px;
    
    padding: 16px;
}

#contact #form-email {
    
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-style:dashed;
    border-radius: 25px;
    flex: 1;
    padding: 16px;
    box-sizing: border-box;
}

#contact textarea {
    
    font-weight: 300;
    width: 100%;
    height: 100px;
    margin-top: 8px;
    background-color: transparent;
    border: 1px solid #A9B1AA;
    border-style:dashed;
    border-radius: 25px;    
    box-sizing: border-box; 
    padding: 16px;
    resize: none;
}

#contact #contact-form button {
    height: 45px;
    width: 200px;
    font-family: "Unbounded", sans-serif;
    font-weight: 300;
    border: 1px solid #141314;
    border-radius: 25px;
    background-color: transparent;
    align-self: flex-end;
    color: #141314;
    background-color: #EAEAEB;
    cursor: pointer;
    transition: all 0.3s ease;
}

#contact #contact-form button:hover {
    background-color: #141314;
    color: #A9B1AA; 
}

#contact #form-name:focus,
#contact #form-email:focus,
#contact textarea:focus {
    outline: none; /* Remove default outline on focus */
    border-color: black; /* Set the border color when input is focused */
}

@media only screen and (max-width: 750px) {
    #contact-bottom-row {
        flex-direction: column-reverse;
    }

    #contact #contact-form button {
        align-self: flex-end;
    }

    #contact-info {
        flex-direction: column;
        margin-top: 8px;
        align-items: flex-start;
        position: relative;
        bottom: 53px;
    }

    #contact-info-email {
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 500px) {
    #contact-info {
        position: static;
    }

    #contact {
        height: auto
    }
}

@media only screen and (max-width: 485px) {
    #form-row {
        flex-direction: column;
    }

    #form-row #form-name {
        margin-right: 0;
        margin-bottom: 8px;
    }

    
}
