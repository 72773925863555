
#nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 8px;
    height: 40px;
    border: 1px solid #141314;
    border-radius: 25px;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 999;
    background-color:#141314;
}

.visible {
    opacity: 1 !important;
    transition: all 0.3s ease;

}

.nav-link {
    font-size: 1.2rem;
    font-weight: 300;
    padding-right: 60px;
    color: #A9B1AA;
    text-decoration: none;
    transition: font-weight 0.3s ease;
}

.nav-link:first-child {
    margin-left: 8px;
}

.nav-link:hover {
    cursor: pointer;
    font-weight: 600;
    color:#eaeaeb;
}

#nav-icons {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

#nav-icons svg {
    margin:0 15px;
    color: #A9B1AA;
    transition: all 0.2s ease;
}

#nav-icons #icon-links a:hover svg {
    color:#eaeaeb;
}

#closeBtn {
    height: 30px;
    width: 30px;
    position: relative;
    margin-right: 0px;
    margin-left: 40px;
}

#closeBtn svg {
    margin: 0;
}

#closeButtonNavBar line, #closeButtonNavBar path {
    transition: all 0.3s ease;
}

#closeButtonNavBar:hover line {
    stroke: #eaeaeb;
    
}

#closeButtonNavBar:hover path {
    fill: #a9b1aa;
}



@media screen and (max-width: 1348px) {
    .nav-link {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1287px) {
    .nav-link {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1233px) {
    .nav-link {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1133px) {
    .nav-link {
        font-size: 1.2rem;
    }

    .nav-link:last-child {
        padding-right: 0;
    }
}


/*TU NA MOBILKI JUZ */
@media screen and (max-width: 950px) {
    .nav-link {
        font-size: 1.2rem;
        padding-right: 30px;
    }

    
}

@media screen and (max-width: 790px) {
    #nav-bar {
        height: 91vh;
        flex-direction: column-reverse;
    }

    #nav-links {
        align-self: flex-start;
        margin: 30px 0;
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

    .nav-link {
        display: block;
        text-align: left;
        padding-left: 8px;
        font-size: 1.7rem;
        font-weight: 500;
        margin: 40px 0 40px 10px;
    }

    #nav-icons {
        margin-top: 8px;
        width: 100%;
        justify-content: space-between;
    }

    #icon-links {
        margin-left: 6px;
    }
    
    #closeBtn {
        right: 7px;
        top: 2px;
    }
}

