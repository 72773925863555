
header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
}
.flex-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    max-height: 60px;
    width:100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color:rgba(234, 234, 235, 1);
}

#logo-container {
    margin: 0;
    display: flex;
    align-items: center;
    cursor: default;
    height: 50px;
}

#logo-container img {
    height: 25px;
    margin-top: 5px;
}



#nav-icon-container {
    display:block;
    padding-bottom: 5px;
    cursor: pointer;
}


@media only screen and (max-width: 900px) {
    header {
        max-width: 100vw;
    }
}
