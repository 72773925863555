#gallery-header {
    height: 300px;
    display: flex;
    align-items: flex-end;
}

#gallery-header h1 {
    font-size: 4rem;
    font-weight: 300;
    margin: 0;
    margin-bottom: 40px;
    line-height: 1;
}